
.header {
    background-color: #960000;
    display: flex;
    align-items: center;
    height: 70px;
    justify-content: space-between;
    padding: 20px 20px;

    .profile {
      display: none;
    }
  
    .logo {
      height: 35px;
      margin-bottom: 5px;
    }

  @media (min-width: 1200px) {
      .menu {
        display: none;
      }
      
      .profile {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #fff;

        img{
          width: 40px;
          background-color: #fff;
          height: 40px;
          margin-right: 15px;
          border-radius: 40px;
        }
      }
  
      .logo {
        height: 45px;
      }
    }
  }
  
.ant-drawer-close {
  color: #fff !important;
}

.ant-drawer-title {
  color: #fff !important;
}
  